import React from "react"
import { TextButton } from "../textButton"
import { Container, Content } from "./loadMore.styles"
import { LoadMoreProps } from "./loadMore.types"

export const LoadMore = ({label,onClick}:LoadMoreProps) => {
  return (
    <Container>
      <Content>
        <TextButton label={label} onClick={onClick} />
      </Content>
    </Container>
  )
}