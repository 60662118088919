import styled from "styled-components"
import { BLOCK_LAYOUT, CONTAINER, getVerticalSpacingAsStyle, VerticalSpacingType, VerticalSpacingVariant } from "../../../theme/layout"

export const Content = styled.div`
  ${BLOCK_LAYOUT}
`

export const Container = styled.div`
  ${CONTAINER}
  ${getVerticalSpacingAsStyle(VerticalSpacingType.PADDING,VerticalSpacingVariant.LARGE,{top:false})}
`