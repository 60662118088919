import React, { Children } from "react"
import { Container } from "./textButton.styles"
import { TextButtonProps } from "./textButton.types"

export const TextButton = ({label,onClick}:TextButtonProps) => {
  return (
    <Container onClick={onClick}>
      {label}
    </Container>
  )
}