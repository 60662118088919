import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { concat, get, sortBy } from 'lodash';
import { SEO } from '../components/common/seo';
import { getSchema } from '../common/schema';
import { getInternalURL } from '../common/link';
import { parseStories, parseUpdates } from '../common/content';
import { StorySection } from '../components/story/section';
import { useDictionary } from '../context/dictionary';
import { useLocale } from '../context/locale';
import { loadUpdates } from '../services/datocms';
import { HeadingSize, HeadingVariant } from '../components/common/heading/heading.types';
import { LoadMore } from '../components/common/loadMore';

export const query = graphql`
  query LatestQuery($id: String!, $locale: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageLatest(
      id: { eq: $id }
    ) {
      model {
        apiKey
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      description
    }
    versions: allDatoCmsPageLatest{
      nodes {
        locale
        model {
          apiKey
        }
      }
    }
    updates: allDatoCmsUpdate(
      filter: {locale: {eq: $locale}, dateString: {ne: null}},
      sort: {fields: dateString, order: DESC},
      limit : 1
    ) {
      nodes {
        originalId
        stories {
          ...StoryCard
        }
      }
      totalCount
    }
  }
`;

const Latest = ({ data, pageContext }: any) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const latest = get(data, 'updates.nodes[0]');
  const versions = get(data, 'versions.nodes');
  const totalCount = get(data, 'updates.totalCount');

  if (!page || !settings || !site) return null;

  const labelMore = useDictionary('labelMore');

  const schema = getSchema({ settings, site, page, pageContext });

  const update = {
    heading: page.title,
    description: page.description,
    stories: parseStories(get(latest,"stories",[])),
  }

  const locale = useLocale();

  const [loadedItems, setLoadedItems] = useState([]);
  const [active, setActive] = useState(totalCount > 0);

  const controller = new AbortController();

  useEffect(() => {
    return () => {
      // Cleanup
      controller.abort();
    };
  }, []);

  const handleButton = async () => {
    if(active){
      setActive(false);
      const updates = await loadUpdates(controller.signal,locale,{excludeOriginalIds:[latest.originalId]},{first:pageContext.paginationSize,skip:loadedItems.length})
     
      setLoadedItems(concat(loadedItems,parseUpdates(updates,locale)));
      setActive(true);
    }
  }

  return (
    <Fragment>
      <SEO 
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <StorySection {...update} headingSize={HeadingSize.LARGE} headingVariant={HeadingVariant.UNDERLINE}  />
      {loadedItems.map((item,index) => <StorySection key={index} {...item} headingSize={HeadingSize.LARGE} headingVariant={HeadingVariant.UNDERLINE} borderTop={true} />)}
      {loadedItems.length < totalCount - 1 && <LoadMore label={labelMore} onClick={active ? handleButton : undefined} />}
    </Fragment>
  );
};

export default Latest;