import styled from "styled-components";
import { getTextHover } from "../../../theme/animation";
import { getTypography, Type } from "../../../theme/typography";

export const Container = styled.button`
  ${getTypography(Type.HEADING_4)}
  ${({theme}) => getTextHover(theme.foreground.primary)}
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.background.secondary};
`